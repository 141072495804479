import '../styling/homepage.scss';
import '../scripts/functions/forms';
//import '../scripts/functions/date-range-picker';
import '../scripts/functions/header-sticky-sentinels';
import 'bootstrap';
import "../scripts/functions/navigatie";
import '../scripts/functions/merk-en-type-autocomplete.js';

import '../scripts/functions/dark-mode.js';
import '../scripts/functions/burstner-fix';


var $ = require('jquery');
window.jQuery = $;
window.$ = $;
