$(function() {
  function addZIndexToParent(show) {
    //add z-index so dropdown is actually visible
    if ($('#home-search').length === 1) { //homepage check
      if (show === true) {
        $('#home-search').css('z-index', '705');
      } else {
        $('#home-search').removeAttr('style');
      }
    }
  }
    function getBrands(input) {
        const ajaxUrl = "/screens/resources/brands-type.php?brands=" + input;



        $.ajax({
            type: "GET",
            url: ajaxUrl,
            success: function(data) {
                const json = $.parseJSON(data);
                if ($('#brands').is(':focus')) $('#brand').removeClass('d-none');
                if ($('#types').is(':focus')) $('#type').removeClass('d-none');

                $('#brand, #type').empty();

                $(json).each(function(i, item) {
                    //console.log(item.brands[0].type)
                    // $('#brands').append(item.brands);
                    if (item.brands) {
                        $.each(item.brands, function(key, val) {
                            $('#brand').append('<span value="' + val.brandname + '">' + val.brandname + '</span>');
                        });
                    }
                    if (item.brands[0]) {
                        $.each(item.brands[0].type, function(key, val) {
                            $('#type').append('<span value="' + val + '">' + val + '</span>');
                        });
                    }
                })
                $('#brand span').click(function() {
                    $('#brand span.selected').removeClass('selected');
                    $(this).addClass('selected');
                    $('#brands').val($(this).attr('value')).trigger('change');
                    $('#brand').addClass('d-none');
                    addZIndexToParent(false);
                })
                $('#type span').click(function() {
                    $('#type span.selected').removeClass('selected');
                    $(this).addClass('selected');
                    $('#types').val($(this).attr('value')).trigger('change');
                    $('#type').addClass('d-none');

                })
                if ($('#brand').is(':empty')) {
                  $('#brand').addClass('d-none');
                  addZIndexToParent(false);

                }
                if ($('#type').is(':empty')) $('#type').addClass('d-none');

            }
        })
    }
    $('#brands').focus(function() {
        $('#brand').removeClass('d-none');
        addZIndexToParent(true);

        $('#type').addClass('d-none');
        getBrands($(this).val());
    });
    $('#types').focus(function() {
        $('#type').removeClass('d-none');
        $('#brand').addClass('d-none');
        getBrands($('#brands').val());
    });


    function goto2(event) {
        let noAction = '#brands, #brand *';
        if (!event.target.matches(noAction)) {
            $('#brand').addClass('d-none');
            addZIndexToParent(false);

        }
    }
    document.addEventListener('click', goto2);

    function goto3(event) {
        let noAction = '#types, #type *';
        if (!event.target.matches(noAction)) {
            $('#type').addClass('d-none');
        }
    }
    document.addEventListener('click', goto3);

    $('#brands').keyup(function(e) {
        function scrollToSelected() {
            const objDiv = $('#brand')[0];
            const div = $('#brand span.selected')[0];
            const selectedOffset = div.offsetTop;
            const objHeight = objDiv.clientHeight;
            if (e.keyCode == 40 || e.keyCode == 39) {
                if (selectedOffset > objHeight) {
                    $('#brand').scrollTop(div.offsetTop)

                }
            } else {
                if (selectedOffset <= objHeight) {
                    $('#brand').scrollTop(div.offsetTop)

                }
            }
        }

        if (e.keyCode == 40 || e.keyCode == 38 || e.keyCode == 13 || e.keyCode == 37 || e.keyCode == 39) {
            if (e.keyCode == 40 || e.keyCode == 39) {
                if ($('#brand span.selected').length === 0) {
                    $('#brand span:first').addClass('selected');
                } else {
                    const nextElem = $('#brand span.selected').removeClass('selected').next();
                    if (nextElem.length !== 0) {
                        nextElem.addClass('selected')
                    } else {
                        $('#brand span:first').addClass('selected');
                    }
                }
                scrollToSelected();
            }
            if (e.keyCode == 38 || e.keyCode == 37) {
                if ($('#brand span.selected').length === 0) {
                    $('#brand span:last').addClass('selected');
                } else {
                    const prevElem = $('#brand span.selected').removeClass('selected').prev();
                    if (prevElem.length !== 0) {
                        prevElem.addClass('selected')
                    } else {
                        $('#brand span:last').addClass('selected');
                    }
                }
            }
            if (e.keyCode == 13) {
                $('#brand').addClass('d-none');
            }
            if (e.keyCode == 9) {
                $('#types').focus();
            }
            if ($('#brand span.selected').length !== 0) {
                $('#brands').val($('#brand span.selected').attr('value'))
            }
        } else {
            getBrands($(this).val())
        }

    });


    $('#types').keyup(function(e) {
        function scrollToSelected() {
            const objDiv = $('#type')[0];
            const div = $('#type span.selected')[0];
            const selectedOffset = div.offsetTop;
            const objHeight = objDiv.clientHeight;
            if (e.keyCode == 40 || e.keyCode == 39) {
                if (selectedOffset > objHeight) {
                    $('#type').scrollTop(div.offsetTop)

                }
            } else {
                if (selectedOffset <= objHeight) {
                    $('#type').scrollTop(div.offsetTop)

                }
            }
        }
        if (e.keyCode == 40 || e.keyCode == 38 || e.keyCode == 13 || e.keyCode == 37 || e.keyCode == 39) {
            if (e.keyCode == 40 || e.keyCode == 39) {
                if ($('#type span.selected').length === 0) {
                    $('#type span:first').addClass('selected');
                } else {
                    const nextElem = $('#type span.selected').removeClass('selected').next();
                    if (nextElem.length !== 0) {
                        nextElem.addClass('selected')
                    } else {
                        $('#type span:first').addClass('selected');
                    }
                }
                scrollToSelected();
            }
            if (e.keyCode == 38 || e.keyCode == 37) {
                if ($('#type span.selected').length === 0) {
                    $('#type span:not(.d-none):last').addClass('selected');
                } else {
                    const prevElem = $('#type span.selected').removeClass('selected').prev();
                    if (prevElem.length !== 0) {
                        prevElem.addClass('selected')
                    } else {
                        $('#type span:last').addClass('selected');
                    }
                }
                scrollToSelected()
            }
            if (e.keyCode == 13) {
                $('#type').addClass('d-none');
            }
            // if (e.keyCode == 9) {
            //   console.log("types tab");
            //   $(this).closest('.form-group').next().find('input, select').focus();
            //   $('#type').addClass('d-none');
            // }
            if ($('#type span.selected').length !== 0) {
                $('#types').val($('#type span.selected').attr('value'))
            }
        } else {
            var searchVal = $(this).val();
            var filterItems = $('#type span');

            if (searchVal != '') {
                filterItems.addClass('d-none');
                $('#type span[value*="' + searchVal.toLowerCase() + '"]').removeClass('d-none');
            } else {
                filterItems.removeClass('d-none');
            }
        }

    });

    $("#brands, #types").on("focus", function() {
        let mql = window.matchMedia('(max-width: 767px)');

        if (mql.matches) {
            var e = $(this);

            $([document.documentElement, document.body]).animate({
                scrollTop: e.offset().top - 90
            }, 100);
        }
    });
});
