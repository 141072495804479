// import '../../scripts/plugins/awselect.js';
//
// $(document).ready(function() {
//   $('select').awselect({
//       background: "#f2f2f2", //the dark blue background
//       active_background: "#aecc52", // the light blue background
//       placeholder_color: "inherit", // the light blue placeholder color
//       placeholder_active_color: "#212529", // the dark blue placeholder color
//       option_color: "#212529", // the option colors
//       vertical_padding: "7px", //top and bottom padding
//       horizontal_padding: "6px", // left and right padding,
//       immersive: true // immersive option, demonstrated at the next example
//   });
// });
