$(function() {

    // Session vullen met zoek optie
    // $('.dropdown-item').click(function(event) {
    //     if ($(this).is('a')) {
    //
    //         event.preventDefault();
    //
    //         var zoekenOp = this.text.replace(/\s+/g, '').toLowerCase();
    //         let href = $(this).attr("href");
    //
    //         console.log(zoekenOp);
    //
    //         $.ajax({
    //             type: "GET",
    //             url: "/ajax-search-setsession.php?search_session=" + zoekenOp,
    //             success: function(data) {
    //                 window.location.href = href;
    //             },
    //         });
    //     }
    // });

    //open dropdowns on hover on desktop, and scroll them into view on mobile
    function hoverOrNot() {
        $(".topnav").addClass("topnav-desktop").removeClass("topnav-mobile");

        var mobilemenuwidth = window.matchMedia("(min-width: 992px)");
        if (mobilemenuwidth.matches) {

            $(".dropdown-menu").on("mouseleave", function() {
                $(this).removeClass("show");
                $(this).closest(".dropdown").removeClass("show");
                $(this).prev().attr("aria-expanded", "false");
            });
            $(".topnav .nav-item .nav-link").on("click", function() {
                if ($(".topnav").hasClass("topnav-desktop")) {
                    if ($(this).attr("aria-expanded") === "true") {
                        let href = $(this).attr("href");
                        if (window.location.href.indexOf("blog") > -1) {
                            window.open(
                                href,
                                '_blank' // <- This is what makes it open in a new window.
                            );
                        } else {
                            window.location.href = href;
                        }
                    }
                }
            });

            $(".header-nav .nav-item .nav-link").on("mouseenter", function() {
                if ($(".topnav").hasClass("topnav-desktop")) {
                    if ($(this).attr("aria-expanded") === "false") {
                        $(this).trigger("click");
                    }
                }
            });
            $(".navbar-nav .nav-item .dropdown").on("mouseenter", function() {
                if ($(this).find(".dropdown-toggle").attr("aria-expanded") === "false") {
                    $(this).find(".dropdown-toggle").trigger("click");
                }
            });
        }
        var mobilemenuwidth2 = window.matchMedia("(max-width: 991px)");
        if (mobilemenuwidth2.matches) {
            $(".topnav").addClass("topnav-mobile").removeClass("topnav-desktop");
            $(".topnav .nav-item .nav-link[aria-expanded='false']").click(function() {
                if ($(".topnav").hasClass("topnav-mobile")) {
                    var e = $(this);
                    setTimeout(function() {
                        $([document.documentElement, document.body]).animate({
                            scrollTop: e.offset().top - 15
                        }, 100);
                    }, 10);
                }
            });

        }
    }
    hoverOrNot();
    $(window).resize(hoverOrNot);

    $(".topnav .badge").each(function() {
        var badgeNumber = $(this).text();
        if (badgeNumber.length > 3) {
            var badgeNumberFormatted = parseFloat(badgeNumber).toLocaleString('us');
            $(this).text(badgeNumberFormatted);
        }
    });

    //have dropdown stay open after dropdown-item click
    $(".topnav .dropdown-item, .topnav .btn,  header .dropdown-item, .darkmode-toggle").click(function() {
        var currentDropdown = $(this).closest(".dropdown");
        currentDropdown.on("hide.bs.dropdown", function() {
            return false;
        });
    });
    $("#loginDropdownMenuLink").on("click", function() {
        if ($(this).attr("aria-expanded") === "true") {
            let href = $(this).attr("href");
            window.location.href = href;
        }

    });


    $('#navbar-toggler-hamborgir').click(function() {
        $(this).toggleClass('open');
    });
});
