$(function() {
    if ('IntersectionObserver' in window) {

      // function displayEntry(entry) {
      //   let entryText =   document.querySelector(".entry");
      //   entryText.textContent  = `Intersecting: ${entry.isIntersecting}`;
      // }

      const topnav = $('.topnav')
      // const filters = $('#filter-options-panel');
      // const check = filters.length > 0;

      let callback = function (entries, observer) {
        entries.forEach(entry => {
          //displayEntry(entry);
          if (entry.isIntersecting) {
            topnav.removeClass('sticky')
            // if (check) {
            //   filters.removeClass('topnav-sticky')
            // }
          } else {
            topnav.addClass('sticky')
            // if (check) {
            //   filters.addClass('topnav-sticky')
            // }
          }
        });
      };



      let observer = new IntersectionObserver(callback);

      let target = document.querySelector("header");

      observer.observe(target);

    }
});
